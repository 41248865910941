<template>
  <div class="page">
    <div class="search">
      <van-button type="default" @click="showAreaPopup"
        >{{ area }}
        <van-icon name="arrow-down" />
      </van-button>
      <van-button type="default" @click="showYearPopup"
        >{{ year }}
        <van-icon name="arrow-down" />
      </van-button>
    </div>
    <van-popup
      round
      v-model="showArea"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <ul class="year">
        <li
          v-for="(item, index) in config"
          :key="index"
          @click="handleArea(item, index)"
          :class="item.provinceId == provinceId ? 'active' : ''"
        >
          {{ item.provinceName }}
          <van-icon name="success" v-if="item.provinceId == provinceId" />
        </li>
      </ul>
    </van-popup>
    <van-popup
      round
      v-model="showYear"
      position="bottom"
      :style="{ height: '80%' }"
    >
      <ul class="year">
        <li @click="handleYear('年份')" :class="year === '年份' ? 'active' : ''">
          年份
          <van-icon name="success" v-if="year === '年份'" />
        </li>
        <li
          v-for="(item, index) in areaIndex&&config[areaIndex].years"
          :key="index"
          @click="handleYear(item)"
          :class="item == year ? 'active' : ''"
        >
          {{ item }}
          <van-icon name="success" v-if="item == year" />
        </li>
      </ul>
    </van-popup>
    <div class="table">
      <div class="table_title">{{ year }} · {{ area }}</div>
      <el-table class="table_data" :data="listData" style="width: 100%">
        <el-table-column width="60px" prop="year" label="年份"> </el-table-column>
        <el-table-column prop="batch" label="批次/段"> </el-table-column>
        <el-table-column width="60px" prop="course" label="科目"> </el-table-column>
        <el-table-column prop="score" label="控制分数线"> </el-table-column>
        <el-table-column label="压线分区间"> 
           <template slot-scope="scope" v-if="scope.row.pressureScore">
            {{ scope.row.score}} ~ {{scope.row.pressureScore}}
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noData from '@/assets/image/nodata.png';

export default {
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
    }),
  },
  data() {
    return {
      listData: [], //全部院校
      config: [],
      noData: noData,
      area: '',
      year: '年份',
      provinceId: 21,
      showArea: false,
      showYear: false,
      areaIndex: 0,
    };
  },
  watch: {
    currentProvince: function(val) {
      console.log('currentProvince', val);
      // this.area = val;
    },
  },
  async mounted() {
    await this.getEnrolldataConfig();
    await this.getEnrolldata();
  },
  methods: {
    //Area
    showAreaPopup() {
      this.showArea = true;
    },
    handleArea(item, index) {
      this.provinceId = item.provinceId;
      this.area = item.provinceName;
      this.showArea = false;
      this.areaIndex = index;
      this.getEnrolldata();
    },
    //year
    showYearPopup() {
      this.showYear = true;
    },
    handleYear(item) {
      this.year = item;
      this.showYear = false;
      this.getEnrolldata();
    },
    //获取筛选列表
    async getEnrolldataConfig() {
      let apiUrl = `/php/enrolldata.config`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log(this.currentProvince, '==res', res);
        if (res.data.ret == 0) {
          this.config = res.data.data;
          // this.provinceId = this.config[0].provinceId;
          // this.area = this.config[0].provinceName;
           let localProvinceInfo = JSON.parse(
          localStorage.getItem('localProvinceInfo'))|| []

           this.config.find((ele, index) => {
            if (ele.provinceName == localProvinceInfo.name) {
              this.area = ele.provinceName;
              this.areaIndex = index;
              this.provinceId =ele.provinceId;
            }
          });
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //批次
    async getEnrolldata() {
      let year = this.year == '年份' ? '' : this.year;
      let apiUrl = `/php/enrolldata.list?provinceId=${this.provinceId}&year=${year}`;
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.listData = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },

    //专业详情
    handleDetail(item) {},
    handleNodeClick(data) {
      console.log(data);
    },
    handleJob(e) {
      console.log('handleJob', e);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #fff;
  padding-top: 51px;
}
.search {
  display: flex;
  align-items: center;
  padding: 10px 12px 0px;
  .van-button--default {
    color: #323233;
    background-color: #fff;
    border: 1px solid #ebedf0;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    margin-right: 8px;
  }
}
.year {
  padding: 20px 10px;
  font-size: 16px;
  color: #222;
  font-weight: 700;
  li {
    padding: 13px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.active {
  color: #910000;
}
.table {
  .table_title {
    font-size: 12px;
    padding: 10px 15px;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
  }
  .table_data {
    font-size: 12px;
    text-align: center;
  }
  .el-table--enable-row-transition .el-table__body td {
    // text-align: center;
    padding: 6px;
    color: #000;
  }
  .el-table--enable-row-transition .el-table__body td:nth-last-child(1) {
    text-align: center;
  }
  .el-table--enable-row-transition .el-table__body tr:nth-child(odd) td {
    background-color: #fafafa !important;
  }
  .el-table th {
    padding: 6px;
    color: #000;
  }
}
.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  img {
    width: 180px;
  }
  .text {
    font-size: 16px;
    color: #666;
    text-align: center;
    width: 100%;
    font-weight: 600;
    padding-top: 20px;
  }
}
.el-message-box {
  width: 90%;
  .el-button {
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
  }
  .el-button--primary {
    background: #910000;
    border-color: #910000;
    color: #fff;
  }
}
</style>
